import App from './App.svelte'
const contentful = require('contentful')
const client = contentful.createClient({
  // This is the space ID. A space is like a project folder in Contentful terms
  space: '1rfk53fyaoun',
  // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
  accessToken: '-hmsMNLYpsmiy8KMUZE7kLBDXg67LE6aWuRuT-fwMLk',
})

const app = new App({
  target: document.body,
  props: { client },
})

export default app
