// Converts a data URI string into a File object.
export function dataURItoFile(dataURI) {
  // Format of a base64-encoded URL:
  // data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAYAAAAEOCAIAAAAPH1dAAAAK
  var BASE64_MARKER = ';base64,'
  var mime = dataURI.split(BASE64_MARKER)[0].split(':')[1]
  var filename =
    'dataURI-file-' + new Date().getTime() + '.' + mime.split('/')[1]
  var bytes = atob(dataURI.split(BASE64_MARKER)[1])
  var writer = new Uint8Array(new ArrayBuffer(bytes.length))

  for (var i = 0; i < bytes.length; i++) {
    writer[i] = bytes.charCodeAt(i)
  }

  return new File([writer.buffer], filename, { type: mime })
}

export function shareResult(showImage, title, message) {
  const file = dataURItoFile(showImage)

  if (navigator.canShare && navigator.canShare({ files: [file] })) {
    navigator
      .share({
        files: [file],
        title,
        text: message,
      })
      .then(() => console.log('Share was successful.'))
      .catch(error => console.log('Sharing failed', error))
  } else {
    console.log(`Your system doesn't support sharing files.`)
  }
}

export function downloadResult(result, fileName) {
  const link = document.createElement('a')
  link.href = result
  link.setAttribute('download', `${fileName}.png`)
  document.body.appendChild(link)
  link.click()
}
