<script>
  export let isLoading = true
  export let error = false
</script>

<style>
  .loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .spinner {
    position: absolute;
    /* left: 50%;
    top: 50%; */
    height: 60px;
    width: 60px;
    margin: 0px auto;
    -webkit-animation: rotation 0.6s infinite linear;
    -moz-animation: rotation 0.6s infinite linear;
    -o-animation: rotation 0.6s infinite linear;
    animation: rotation 0.6s infinite linear;
    border-left: 6px solid rgba(0, 174, 239, 0.15);
    border-right: 6px solid rgba(0, 174, 239, 0.15);
    border-bottom: 6px solid rgba(0, 174, 239, 0.15);
    border-top: 6px solid rgba(0, 174, 239, 0.8);
    border-radius: 100%;
  }

  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  @-moz-keyframes rotation {
    from {
      -moz-transform: rotate(0deg);
    }
    to {
      -moz-transform: rotate(359deg);
    }
  }
  @-o-keyframes rotation {
    from {
      -o-transform: rotate(0deg);
    }
    to {
      -o-transform: rotate(359deg);
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
  .error {
    text-align: center;
  }
</style>

{#if isLoading}
  <div class="loading">
    {#if error}
      <div class="error">
        <p>
          😥 Lo sentimos. No pudimos cargar la información. Intenta de nuevo mas
          tarde
        </p>
      </div>
    {:else}
      <div class="spinner" />
    {/if}
  </div>
{:else}
  <slot />
{/if}
