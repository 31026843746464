<script>
  import { onMount } from 'svelte'
  import { DownloadIcon, Share2Icon } from 'svelte-feather-icons'
  import { shareResult, downloadResult } from './utils/userActions.js'
  import Slider from './Slider.svelte'
  import Loading from './Loading.svelte'

  export let client

  let isLoading = true
  let error = false

  //contetntful variables
  let mainColor = '#00bc8e'
  let message = ''
  let title = 'mi-escarapela'
  let firstPlaceholder = ''
  let secondPlaceholder = ''
  let fileName = 'mi-escarapela'
  let optionsTitle = ''
  let badges = []

  let mainImage
  let showImage
  let badge
  let firstText = ''
  let secondText = ''
  let navigatorShare = false

  onMount(() => {
    let f = new File([''], 'can-share')
    if (navigator.canShare && navigator.canShare({ files: [f] })) {
      navigatorShare = true
    }
    const path = window.location.pathname.replace('/', '')
    console.log(path)
    getContent(path)
  })

  function centeredText(text, fontSize, color, ctx, width, height) {
    var i = text.length
    i = i * fontSize * 0.62
    if (i > width) {
      i = width
    }
    ctx.fillStyle = 'RGBA(255, 255, 255)'
    ctx.fillRect(
      width / 2 - i / 2,
      height / 2 - (fontSize * 1.5) / 2,
      i,
      fontSize * 1.5
    )
    ctx.font = fontSize.toString() + 'px monospace'
    ctx.fillStyle = color
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'

    ctx.fillText(text, width / 2, height / 2)
  }

  function putBadge(selected) {
    const image1 = new Image()
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    image1.onload = function() {
      const imageWidth = image1.width
      const imageHeight = image1.height

      canvas.width = imageWidth
      canvas.height = imageHeight
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(image1, 0, 0)

      const image2 = new Image()
      image2.onload = function() {
        const badgeWidth = 300
        const badgeHeight = 300
        ctx.drawImage(
          image2,
          imageWidth - badgeWidth - 30,
          30,
          badgeWidth,
          badgeHeight
        )

        centeredText(
          firstText.trim(),
          80,
          'black',
          ctx,
          imageWidth,
          imageHeight
        )
        centeredText(
          secondText.trim(),
          50,
          'black',
          ctx,
          imageWidth,
          imageHeight + 230
        )

        showImage = canvas.toDataURL('image/png')
      }

      image2.crossOrigin = 'Anonymous'
      image2.src = selected
      badge = selected
      // if (selected) {
      //   image2.crossOrigin = 'Anonymous'
      //   image2.src = selected
      //   badge = selected
      // } else {
      //   image2.crossOrigin = 'Anonymous'
      //   image2.src = badge
      // }
    }

    image1.crossOrigin = 'Anonymous'
    image1.src = mainImage
  }

  // function handleAddBadge(selected) {
  //   badge = selected
  // }

  function addText(e) {
    e.preventDefault()
    putBadge(badge)
  }

  async function getContent(entryId) {
    try {
      const entry = await client.getEntry(entryId)
      const {
        nombre,
        color,
        insignias,
        imagenPrincipal,
        mensaje,
        nombreArchivo,
        primerTexto,
        segundoTexto,
        tituloOpciones,
      } = entry.fields
      mainImage = `https:${imagenPrincipal.fields.file.url}`
      mainColor = color
      message = mensaje
      title = nombre
      firstPlaceholder = primerTexto
      secondPlaceholder = segundoTexto
      fileName = nombreArchivo
      optionsTitle = tituloOpciones

      badges = insignias.map(insignia => `https:${insignia.fields.file.url}`)
      badge = badges[0]

      isLoading = false
      putBadge(badge)
    } catch (e) {
      error = true
      console.log(e)
    }
  }

  function changeBadge(e) {
    putBadge(e.detail.badge)
  }
</script>

<style>
  main {
    display: flex;
    justify-content: center;
  }

  h2 {
    text-align: center;
  }

  img {
    text-indent: -10000px;
  }

  .mainImage {
    width: 100%;
  }
  .mainContainer {
    width: 100%;
    max-width: 600px;
  }
  .downloadContainer {
    margin-top: 1rem;
    padding-top: 0.8rem;
    border-top: 1px solid #333;
    width: 100%;
  }
  .nameContainer {
    margin: 1rem 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .nameInput {
    width: 80%;
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    border: 2px solid #333;
    border-radius: 6px;
    font-size: 1.5rem;
  }
  .nameInput:focus {
    outline: none;
    border-radius: 6px;
    border: 2px solid #333;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2);
  }
  .button {
    background-color: var(--theme-color);
    /* background-color: #f6dc00; */
    width: 100%;
    color: black;
    font-size: 15px;
    border-radius: 6px;
    border-style: none;
    border-width: 3px;
    font-weight: 800;
    padding: 12px 20px;
    cursor: pointer;
    margin: 0.5rem 0;
    box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
  .button:hover {
    background: black;
    color: #fff;
  }
</style>

<Loading {isLoading} {error}>
  <main>
    <div class="mainContainer">

      <h2>{optionsTitle}:</h2>
      <Slider {badges} selected={badge} {mainColor} on:choose={changeBadge} />

      <h2>Ingrese aquí sus datos:</h2>
      <form on:submit={addText} class="nameContainer">
        <input
          type="text"
          placeholder={firstPlaceholder}
          bind:value={firstText}
          class="nameInput" />

        <input
          type="text"
          placeholder={secondPlaceholder}
          bind:value={secondText}
          class="nameInput" />

        <div class="buttonContainer">
          <button
            type="submit"
            class="button"
            style="--theme-color: {mainColor}">
            Agregar datos
          </button>
        </div>
      </form>
      <!-- <canvas bind:this={canvas} width={100} height={100} /> -->
      <img
        class="mainImage"
        src={showImage}
        alt="badge background"
        crossorigin="Anonymous" />
      <div class="downloadContainer">
        <button
          style="--theme-color: {mainColor}"
          class="button"
          on:click={() => downloadResult(showImage, fileName)}>
          <DownloadIcon size="16" />
          Descargar
        </button>
        {#if navigatorShare}
          <button
            style="--theme-color: {mainColor}"
            class="button"
            on:click={() => shareResult(showImage, title, message)}>
            <Share2Icon size="16" />
            Compartir
          </button>
        {/if}
      </div>
    </div>
  </main>
</Loading>
